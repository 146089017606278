import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

interface UseIntersectionProps extends IntersectionObserverInit {
  destroyOnceEntry?: boolean;
}

const useIntersection = (ref: RefObject<HTMLElement>, options: UseIntersectionProps): IntersectionObserverEntry | null => {
  const isEntered = useRef(false);
  const observer = useRef<IntersectionObserver>();
  const [intersectionObserverEntry, setIntersectionObserverEntry] = useState<IntersectionObserverEntry | null>(null);

  const handler = useCallback((entries: IntersectionObserverEntry[]): void => {
    setIntersectionObserverEntry(entries[0]);
  }, []);

  const disconnect = useCallback((): void => {
    observer.current?.disconnect();
    setIntersectionObserverEntry(null);
  }, []);

  useEffect(() => {
    if (!isEntered.current && !!intersectionObserverEntry && options.destroyOnceEntry) {
      if (intersectionObserverEntry.isIntersecting) {
        isEntered.current = intersectionObserverEntry.isIntersecting;
        disconnect();
      }
    }
  }, [disconnect, intersectionObserverEntry, options.destroyOnceEntry]);

  useEffect(() => {
    if (ref.current) {
      observer.current = new IntersectionObserver(handler, options);
      observer.current.observe(ref.current);

      return () => disconnect();
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [ref.current, options.threshold, options.root, options.rootMargin]);

  return intersectionObserverEntry;
};

export default useIntersection;
