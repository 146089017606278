import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import scribble from '../../assets/scribbles/button.svg';
import { Visibility } from '../../types';
import { Link } from 'gatsby';
import formDoodle from '../../assets/doodles/form.svg';

export const Wrapper = styled.section<Visibility>`
  
  display: flex;
  width: 100%;
  max-width: ${940 + dimensions.containerPadding * 2}px;
  margin: 22px auto 0 auto;
  padding: 0 ${dimensions.containerPadding}px;
  align-items: center;
  flex-direction: column;
  text-align: center;

  /*
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}*/
`;

export const Heading = styled.header`
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  margin: 0 0 -2px 0;
  font-size: ${dimensions.fontSize.headingMajor}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.headingMajor};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.headingMajor * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: ${dimensions.fontSize.headingMajor * 0.5}px;
    margin-top: -31px;

  }


`;

export const topTitle = styled.h3`
  margin: 0 0 20px 0;
  font-size: 25px;
  font-weight: 700;
  line-height: ${dimensions.lineHeight.headingMajor};
  color: #746666ab;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${25 * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: ${25 * 0.5}px;
    margin-top: -31px;
    padding-bottom: 20px;

  }


`;

export const Content = styled.p`
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};
  width: 570px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    //font-size: ${dimensions.fontSize.large * 0.8}px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 90%;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    width: 570px;
  }
`;

export const Button = styled(Link)`
  display: inline-flex;
  height: 56px;
  margin-top: 6px;
  padding: 0 32px;
  border-radius: 28px;
  position: relative;
  background-color: ${colors.blue};
  align-items: center;
  font-size: ${dimensions.fontSize.regular};
  font-weight: 600;
  color: ${colors.white};
  text-transform: uppercase;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background-color: #1f62ff;
    text-decoration: none;
  }

  &:before,
  &:after {
    content: '';
    width: 24px;
    height: 33px;
    position: absolute;
    top: 12px;
    background: url(${scribble}) no-repeat 0 0;
    background-size: contain;
  }

  &:before {
    left: -32px;
  }
  &:after {
    right: -32px;
    transform: scale(-1, 1);
  }
`;

export const Footer = styled.footer`
  max-width: 70%;
  margin: 5px auto 0 auto;
  color: ${colors.lightGray};
  line-height: 1.625;
  text-align: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    
  }
`;

export const Doodle = styled.span`
  position: relative;

  &:after {
    content: '';
    width: 127px;
    height: 56px;
    position: absolute;
    top: -2px;
    left: -4px;
    background: url(${formDoodle}) no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: ${breakpoints.sm}px) {
      display:none;
    }
  }
`;
