import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import useIntersection from './useIntersection';

interface useVisibilityDetectionResult {
  isVisible: boolean;
}

export const useVisibilityDetection = (
  element: MutableRefObject<null>,
  threshold?: [number, number] | number,
  bothSides = false,
): useVisibilityDetectionResult => {
  const options = useMemo(
    () => ({
      destroyOnceEntry: !bothSides,
      root: null,
      rootMargin: '0px',
      threshold: threshold || [0.2, 0.75],
    }),
    [bothSides],
  );
  const { isIntersecting } = useIntersection(element, options) || {};
  const isVisible = useRef(false);

  useEffect(() => {
    if (bothSides) {
      isVisible.current = !!isIntersecting;
    } else if (!isVisible.current) {
      isVisible.current = !!isIntersecting;
    }
  }, [isIntersecting]);

  return {
    isVisible: isVisible.current,
  };
};
